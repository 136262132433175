// @import url('fonts/fonts.css');
$tablet-max: 991px;
$desktop-sm-min: 992px;

$brand-brown: #7e746f;
$button-bg:   #e5aa1b;

.hide-xs {
  @media (max-width: $tablet-max) {
    display: none!important;
  }
}

.hide-desktop {
  @media (min-width: $desktop-sm-min) {
    display: none!important;
  }
}


/* Headline Font */
@mixin evelethclean_thin {
  font-family: 'evelethclean_thin'!important;
  font-weight: normal!important;
  font-style: normal!important;
}

/* Menu Font */
@mixin evelethclean_regular {
  font-family: 'evelethclean_regular'!important;
  font-weight: normal!important;
  font-style: normal!important;
}

/* Body Font */
@mixin ainslienorm_regular {
  font-family: 'ainslienorm_regular'!important;
  font-weight: normal!important;
  font-style: normal!important;
}

.wsite-social-item {
  color: $brand-brown!important;
}

.wsite-mobile-menu {
  li {
    a {
      color: $brand-brown!important;
    }
  }
}



body.home-page {
  div.nav-wrap {
    div#logo {
      // display: none!important;
      padding: 0!important;
      height: 0;
      border: 0;
      background-color: $brand-brown;
      .hide-desktop {
        width: 80px;
        img.hide-xs {
          display: none!important;
        }
      }

    }
  }
}

body {
  div#logo {
    img.hide-desktop {
      width: 100px;
    }
  }
}

body:not(.home-page) {
  div#logo {
    background-color: $brand-brown;
    span {
      @media (min-width: $desktop-sm-min) {
        padding: 0;
      }
      a {
        @media (min-width: $desktop-sm-min) {
          display: block;
          width: 250px;
          padding-top: 0;
          padding-bottom: 0;
        }
        img {
          @media (min-width: $desktop-sm-min) {
            max-height: none;
            margin: auto;
            max-width: none;
          }
        }
      }
    }
  }
}

body {
  // Set Menu Font
  .menu .wsite-menu-default li a {
    @include evelethclean_regular;
  }

  // Set Body Font
  .wsite-elements.wsite-not-footer:not(.wsite-header-elements) div.paragraph,
  .wsite-elements.wsite-not-footer:not(.wsite-header-elements) p,
  .wsite-elements.wsite-not-footer:not(.wsite-header-elements) .product-block .product-title,
  .wsite-elements.wsite-not-footer:not(.wsite-header-elements) .product-description,
  .wsite-elements.wsite-not-footer:not(.wsite-header-elements) .wsite-form-field label,
  .wsite-elements.wsite-not-footer:not(.wsite-header-elements) .wsite-form-field label,
  #wsite-content div.paragraph,
  #wsite-content p,
  #wsite-content .product-block .product-title,
  #wsite-content .product-description,
  #wsite-content .wsite-form-field label,
  #wsite-content .wsite-form-field label,
  .blog-sidebar div.paragraph,
  .blog-sidebar p,
  .blog-sidebar .wsite-form-field label,
  .blog-sidebar .wsite-form-field label {
    @include ainslienorm_regular;
  }

  // Set Headline Font
  .wsite-elements.wsite-not-footer:not(.wsite-header-elements) h2,
  .wsite-elements.wsite-not-footer:not(.wsite-header-elements) .product-long .product-title,
  .wsite-elements.wsite-not-footer:not(.wsite-header-elements) .product-large .product-title,
  .wsite-elements.wsite-not-footer:not(.wsite-header-elements) .product-small .product-title,
  #wsite-content h2, #wsite-content .product-long .product-title,
  #wsite-content .product-large .product-title,
  #wsite-content .product-small .product-title, .blog-sidebar h2  {
    @include evelethclean_regular;
  }

  .wsite-button .wsite-button-inner,
  .wsite-button-highlight .wsite-button-inner,
  .wsite-button-large.wsite-button-highlight .wsite-button-inner,
  .wsite-editor .wsite-button-highlight .wsite-button-inner,
  .wsite-editor .wsite-button-large.wsite-button-highlight .wsite-button-inner {
    background-color: $button-bg!important;
    border-color: $button-bg!important;
    color: white!important;
  }
}


.wsite-body-section.wsite-section-bg-image {
  @media (max-width: $tablet-max) {
    height: 250px!important;
  }
}
